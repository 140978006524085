<template>
  <div class="small-12 columns main">
    <div class="small-12 medium-4 large-4 columns images" align="center">
      <!-- <div v-if="showImages.hero" class="small-12 columns primary"> -->
      <div class="small-12 columns primary">
        <span
          ><img
            class="hero"
            :src="hero.url"
            :alt="hero.content[$i18n.locale].name"
        /></span>
        <p v-if="!secondaryImages" class="sml">
          <span
            ><a @click.prevent="showLightbox(hero)"
              ><i class="fa fa-search" aria-hidden="true"></i>
              {{ $t('client.product.zoom') }}</a
            ></span
          >
        </p>
      </div>
      <div v-if="secondaryImages">
        <hr />
        <ul
          class="regallery"
          style="list-style: none outside; margin: 0; padding: 0"
        >
          <!-- <li v-for="image in secondaryImages"><img :src="image.url" :alt="image.content[$i18n.locale].name"></li> -->
          <li
            style="display: inline; padding: 0 1rem"
            v-for="image in product.images"
            :key="image._id"
          >
            <a @click.prevent="showLightbox(image)"
              ><img
                style="width: 5rem"
                :src="image.url"
                :alt="image.content[$i18n.locale].name"
            /></a>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="small-12 medium-4 large-4 columns images" align="center">
		<div class="small-12 columns primary">
			<span><img :src="reProduct.images[0].url || ''" :alt="reProduct.name"></span>
			<p class="sml"><span><a> {{ $t("client.product.zoom") }}</a></span></p>
		</div>
	</div> -->

    <div class="small-12 medium-8 large-8 columns data">
      <div class="small-12 columns desc tight">
        <h3 v-html="twolines(reProduct.content[$i18n.locale].name)"></h3>
        <p v-html="reProduct.content[$i18n.locale].description"></p>
        <p style="font-size: 1.25rem; padding-top: 0.5rem">
          Price: <strong>${{ product.price }}</strong>
        </p>
        <!-- <div v-if="!program.noActiveQty" class="small-12 columns tight">
          <p style="padding-top: 0.5rem; margin-bottom: 0; margin-right: 2rem; font-size: 0.9rem">
            {{ $t("sections.bookIt.minQtyReq") }}:
            <strong>{{ activeVolume }}</strong>&nbsp;
            <i
              v-show="currentVolume >= activeVolume"
              class="fa fa-check-circle"
              style="color: green"
              aria-hidden="true"
            ></i>
            <i
              v-show="currentVolume < activeVolume"
              class="fa fa-times-circle"
              style="color: red"
              aria-hidden="true"
            ></i>
          </p>
        </div>
        <div class="small-12 columns tight" style="padding-bottom:1rem;">
          <p style="padding-top: 0.5rem; margin-bottom: 0; margin-right: 2rem; font-size: 0.9rem">
            {{ $t("sections.bookIt.totalQty") }}:
            <strong>{{ currentVolume }}</strong>
          </p>
        </div> -->
      </div>

      <!-- v-if="program.settings.communityVolume" -->
      <pricing-tiers
        :product="product"
        :summary="summary"
        @qtyChange="qtyChange"
        @customize="customize"
      ></pricing-tiers>
      <!-- <product-pricing
        v-else
        :product="product"
        :cart="cart"
        :settings="isProgram ? program.settings : collection.settings"
        @qtyChange="qtyChange"
        @customize="customize"
      ></product-pricing> -->
    </div>

    <Lightbox
      :visible="lightboxVisible"
      @hide="lightboxVisible = false"
      id="modal"
      title="Hi"
      maxheight="75%"
      :imgsrc="modalimg.url"
      modalheight="85%"
      modalwidth="50%"
    ></Lightbox>
  </div>
</template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import pricingTiers from './PricingTiers.vue'
// import productPricing from './ProductPricing.vue'
import Lightbox from '@/components/Lightbox.vue'
import imageFile from '@/models/FileModel'
// import acl from '@/../../../templates/site/UI/app/acl-rules'

export default {
  name: 'ProductDetailTile',
  // component lifecycle events
  created() {
    const _profile = _.assign({}, this.user.profile)
    if (!this.user.profile.shoppingcarts) _profile.shoppingcarts = {}
    if (
      this[this.parentType].settings.cart !== 'default' &&
      !_profile.shoppingcarts[this[this.parentType].settings.cart]
    ) {
      _profile.shoppingcarts[this[this.parentType].settings.cart] = {
        items: {},
        details: {},
        label: this[this.parentType].settings.cartLabel
      }
      this.profileUpdate({ user: this.user, profile: _profile })
    }

    this.selectedQtys = this.setInitialQtys()
  },
  mounted() {
    this.$nextTick(() => {
      this.isProgram = this.parentType === 'program'
      this.isCollection = this.parentType === 'collection'
    })
  },
  components: { pricingTiers, Lightbox },
  props: {
    product: {
      default: () => ({})
    },
    summary: Object,
    parentType: String,
    parentContainer: {}
  },
  data() {
    return {
      title: '',
      allowUpload: false, // NB: this should be configurable value
      selectedQtys: {},
      relatedItems: [],
      // section: '',
      isProgram: false,
      isCollection: false,
      // locales: _.keys(this.$store.state.lang),
      addDealerLogo: false,
      upload: null,
      newPreview: null,
      flash: null,
      attachNew: false,
      selectedVar: null,
      showAddToCart: false,
      showImages: { hero: false, variations: false },
      lightboxVisible: false,
      modalimg: { url: null, name: null }
    }
  },
  computed: {
    ...mapState({
      program: ({ Collection }) => Collection.program,
      collection: ({ Collection }) => Collection.collection,
      returnURL: ({ navigationContext }) => navigationContext,
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    cart() {
      const coll = this.isCollection ? this.collection : this.program
      if (coll.settings.cart === 'default') {
        return this.isCollection
          ? this.user.profile.cart
          : this.user.profile.preorders
      } else if (!this.isCollection && this.program.settings.communityVolume) {
        return this.user.profile.preorders
      }
      return this.user.profile.shoppingcarts[coll.settings.cart]
    },
    section() {
      if (!this.parentContainer) return ''
      return this.parentContainer.url
    },
    reProduct() {
      if (this.product[0] && !this.product.familyKey) return this.product[0]
      else return this.product
    },
    producturl() {
      // console.info('whats the url?',)
      if (this.parentType === 'collection') {
        let _add =
          this.reProduct.variationField.key || this.reProduct.variationKey || ''
        _add += _add ? _add + '/' : ''
        return (
          '/shop/' +
          this.section +
          '/product/' +
          this.reProduct.familyKey +
          _add
        )
      } else if (this.parentType === 'program') {
        return `/programs/${this.parentContainer.url}/product/${this.reProduct.familyKey}`
      } else return 'what...'
    },
    lowestPrice() {
      return _.min(_.map(p => p.value, this.product.adjustments))
    },
    hero() {
      if (_.isEmpty(this.product)) return false
      if (this.product.images.length === 1) return this.product.images[0]
      return (
        _.find(i => i.default, this.product.images) ||
        _.sortBy(i => i.order, this.product.images)[0]
      )
    },
    secondaryImages() {
      if (_.isEmpty(this.product)) return false
      if (this.product.images.length < 2) return false
      return _.map(
        i => {
          if (i._id !== this.hero._id) return i
        },
        _.sortBy(i => i.order, this.product.images)
      ).filter(i => i)
    },
    allowCustomOption() {
      return (
        this.product.customizable &&
        (this.selectedQtys[this.product.SKU].qty || 0) >=
          this.product.customMinQty
      )
    }
  },
  methods: {
    ...mapActions([
      'getCollections',
      'getCollection',
      'setCollection',
      'getCollectionProducts',
      'getProgramProducts',
      'profileUpdate',
      'programSummaryUpdate',
      'getProductFamily',
      'getCartProducts',
      'getProgram',
      'ensureProfilePreorders'
    ]),
    showLightbox(img) {
      this.modalimg = img
      this.lightboxVisible = true
      // this.$emit('lightbox.show')
    },
    qtyChange({ sku, qty }) {
      const _qty = typeof qty === 'number' ? qty : parseInt(qty, 10)
      if (this.showMultiloc) {
        if (!this.selectedQtys[sku])
          this.selectedQtys[sku] = { qty: null, locations: {} }
        if (_qty) {
          this.selectedQtys[sku].locations[location] = _qty
        } else {
          this.selectedQtys[sku].locations[location] = null
        }
        this.selectedQtys[sku].qty = _.sum(
          _.values(this.selectedQtys[sku].locations).map(val => val || 0)
        )
        // this.selectedQtys[sku].locations[location._id] = typeof qty === 'number' ? qty : parseInt(qty, 10)
      } else {
        this.selectedQtys[sku].qty = _qty
      }
      if (this.isProgram && this.program.settings.communityVolume)
        this.addPreorder()
      else this.addToCart(location)
      // this.countSelected();
    },
    countSelected() {
      // actually just tracks if an input is different from the stored cart...
      let _changed = false
      // let _cart = this.isCollection
      //   ? this.user.profile.cart
      //   : this.user.profile.preorders
      _.each(i => {
        if (this.cart.items[i] !== this.selectedQtys[i].qty) _changed = true
      }, _.keys(this.selectedQtys))
      this.showAddToCart =
        !_changed &&
        !_.reduce((sum, i) => sum + i.qty || 0, 0, _.values(this.selectedQtys))
    },

    selectVar(v) {
      this.selectedVar = v
    },
    addToCart() {
      const _profile = _.assign({}, this.user.profile)
      const _cartid = this.isProgram
        ? this.program.settings.cart
        : this.collection.settings.cart
      const _cart = _profile[_cartid] || _profile.shoppingcarts[_cartid] //this.isProgram
      // ? _profile.shoppingcarts[this.program.settings.cart]
      // : this.collection.settings.cart === 'default'
      // ? _profile.cart
      // : _profile.shoppingcarts[this.collection.settings.cart]
      let _updateRequired = false
      for (const k in this.selectedQtys) {
        if (!this.selectedQtys[k].qty && _cart.items[k]) {
          delete _cart.items[k]
          delete _cart.details[k]
          _updateRequired = true
        } else if (
          (!_cart.items[k] && this.selectedQtys[k].qty) ||
          (this.selectedQtys[k].qty &&
            this.selectedQtys[k].qty !== _cart.items[k])
        ) {
          _cart.items[k] = this.selectedQtys[k].qty
          _cart.details[k] = {}
          _cart.details[k].added = Date.now()
          if (this.isCollection) {
            _cart.details[k].type = 'collection'
            _cart.details[k].url = this.section
          }
          if (this.isProgram) {
            _cart.details[k].type = 'program'
            _cart.details[k].url = this.section
          }
          _updateRequired = true
        }
      }

      if (_updateRequired) {
        this.profileUpdate({ user: this.user, profile: _profile })
          .then(() => {
            this.selectedQtys = this.setInitialQtys()
            this.$emit('saved.cart')
            this.countSelected()
            this.getCartProducts(_cartid)
          })
          .catch(err => console.log('profileUpdate threw an error', err))
      }
      return true
    },
    addPreorder() {
      const _profile = _.assign({}, this.user.profile)
      let _updateRequired = false
      for (const k in this.selectedQtys) {
        if (!this.selectedQtys[k].qty && _profile.preorders.items[k]) {
          delete _profile.preorders.items[k]
          delete _profile.preorders.details[k]
          _updateRequired = true
        } else if (
          (!_profile.preorders.items[k] && this.selectedQtys[k].qty) ||
          (this.selectedQtys[k].qty &&
            this.selectedQtys[k].qty !== _profile.preorders.items[k])
        ) {
          _profile.preorders.items[k] = this.selectedQtys[k].qty
          _profile.preorders.details[k] = { url: this.section, type: 'program' }
          _profile.preorders.details[k].added = Date.now()

          _updateRequired = true
        }
      }

      if (_updateRequired) {
        this.profileUpdate({
          user: this.user,
          profile: _profile,
          preorder: this.program._id
        })
        // this.programSummaryUpdate(this.program, this.product, this.user, _profile);
        this.selectedQtys = this.setInitialQtys()
        this.$emit('updatedcart')
        this.countSelected()
        // this.getCartProducts();
      }
      return true
    },
    customize(val) {
      const _profile = _.assign({}, this.user.profile)
      const _cart = this.isProgram
        ? _profile.shoppingcarts[this.program.settings.cart]
        : this.collection.settings.cart === 'default'
        ? _profile.cart
        : _profile.shoppingcarts[this.collection.settings.cart]
      if (this.product.items && this.product.items.length) {
        _.each(p => {
          if (_cart.details[p.SKU]) _cart.details[p.SKU].customize = val
        }, this.product.items)
      } else {
        _cart.details[this.product.SKU].customize = val
      }
      this.profileUpdate({ user: this.user, profile: _profile })
    },
    setInitialQtys() {
      let _initialvals = {}
      // let _cart = this.isCollection
      //   ? this.user.profile.cart
      //   : this.user.profile.preorders
      if (!this.product.items || !this.product.items.length) {
        _initialvals[this.product.SKU] = { qty: null }
        if (this.cart.items[this.product.SKU])
          _initialvals[this.product.SKU].qty = this.cart.items[this.product.SKU]
        if (this.showMultiloc) {
          if (this.cart.details[this.product.SKU]) {
            _initialvals[this.product.SKU].qty = _.sum(
              Object.values(this.cart.details[this.product.SKU].locations).map(
                val => val || 0
              )
            )
            _initialvals[this.product.SKU].locations = this.cart.details[
              this.product.SKU
            ].locations
          } else {
            _initialvals[this.product.SKU].locations = {}
          }
        }
      } else {
        _.each(i => {
          _initialvals[i.SKU] = { qty: null }
          if (this.cart.items[i.SKU]) {
            _initialvals[i.SKU].qty = this.cart.items[i.SKU]
          }
          if (this.showMultiloc) {
            if (this.cart.details[i.SKU]) {
              _initialvals[i.SKU].qty = _.sum(
                Object.values(this.cart.details[i.SKU].locations).map(
                  val => val || 0
                )
              )
              _initialvals[i.SKU].locations = this.cart.details[i.SKU].locations
            } else {
              _initialvals[i.SKU].locations = {}
            }
            // _initialvals[i.SKU].locations = this.cart.details[i.SKU] ? this.cart.details[i.SKU].locations : {}
          }
        }, this.product.items)
      }

      if (this.isProgram && this.program.settings.communityVolume)
        this.ensureProfilePreorders()
      // this.showSkus = true;
      return _.assign({}, _initialvals)
    },
    orderAgreement() {
      this.$emit('modal.show')
    },
    modalcb(val, coll, close) {
      if (!val.res) {
        // cancel button
        close()
      } else {
        if (val.agree) {
          const _profile = _.assign({}, this.user.profile)
          _profile.extradata.programAgreement = true
          _profile.extradata.paymentMethod = val.paymentMethod
          this.profileUpdate({ user: this.user, profile: _profile })
          close()
          this.addPreorder()
        } else {
          this.modalFlash = 'You must accept the terms to enter.'
        }
      }
    },
    // NB: for creating files. Not in use this version.
    createFile() {
      const fd = new FormData()
      fd.append('file', this.uploadFile)
      fd.append('user', this.user.email)
      fd.append('recordType', 'user')
      this.$http
        .post('/files/upload', fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          const _res = res.data
          const _f = new imageFile()
          if (!_res.err) {
            _f.set('type', 'user')
            _f.set('user', this.user.email)
            _f.set('filename', _res.filename)
            _f.set('path', _res.path)
            _f.set('url', _res.url)
            _f.set('mimeType', _res.mimeType)
            this.$http
              .post('/files', _f.getData())
              .then(data => {
                console.info('this is data', data)
                // save ref to profile.preorders
                const _profile = this.user.profile
                _profile.preorders.details[this.product.SKU].customize = true
                _profile.preorders.details[this.product.SKU].imgurl = _res.url
                _profile.preorders.details[this.product.SKU].fileId =
                  data.data._id
                this.profileUpdate({ user: this.user, profile: _profile })
                this.clearFile()
              })
              .catch(err => console.error('Error adding file. ', err))
          } else {
            throw new Error(res.err)
          }
        })
        .catch(err => console.error('Error uploading file.', err))
    },
    uploadChange(evt) {
      if (!evt.target.files.length) return
      const reader = new window.FileReader()
      const file = evt.target.files[0]

      reader.onload = () => {
        this.newPreview = reader.result
      }

      if (this.isAllowedType(file)) {
        reader.readAsDataURL(file)
        this.uploadFile = file
      } else {
        this.newPreview = null
        this.uploadFile = null
        this.flash = {
          title: this.$t(`flashmsg.fileTypeNotAllowed.title`),
          msg: this.$t(`flashmsg.fileTypeNotAllowed.msg`)
        }
      }
    },
    clearFile() {
      this.upload = null
      this.uploadFile = null
      this.newPreview = null
      this.flash = null
    },
    isAllowedType(file) {
      return file // need to setup allowed types dictionary
      // return allowed.some(type => mime.lookup(type) === file.type)
    },
    twolines(value) {
      // const _words = value.split(' ');
      // if(_words.length < 4) return _words.slice(0,-1).join(' ') + '<br>' + _words.slice(-1);
      return value // return _words.slice(0,-2).join(' ') + '<br>' + _words.slice(-2).join(' ');
    }
  },
  // locales: {
  //   en: {
  //     title: {
  //       'in-stock-gear': 'In Stock Gear',
  //       uniforms: 'Uniforms',
  //       prebook: 'Prebook'
  //     },
  //     viewBtn: {
  //       'in-stock-gear': 'Get it Now',
  //       uniforms: 'Get it Now',
  //       prebook: 'Get it Now'
  //     }
  //   },
  //   fr: {
  //     title: {
  //       collection: 'Procurez-vous-le maintenant',
  //       program: 'Réservez-le',
  //       other: 'Personnalisez-le'
  //     },
  //     viewBtn: {
  //       collection: 'Procurez-vous-le maintenant',
  //       program: 'Réservez-le',
  //       other: 'Personnalisez-le'
  //     }
  //   }
  // },
  // any custom, imported filters
  filters: {},
  // event listeners
  events: {
    // qtyChange ({ sku, qty }) {
    //   this.selectedQtys[sku].qty = parseInt(qty, 10)
    //   this.addPreorder()
    //   // this.countSelected();
    // },
    // customize (val) {
    //   this.customize(val)
    // },
    'lightbox.close': function() {
      this.lightboxVisible = false
    },
    'select.file': function(file) {
      this.selectedFile = new imageFile(file)
    }
  }
}
</script>

<style>
/* eslint-disable css-lcurlyexpected css-ruleorselectorexpected */
.item-block:nth-of-type (3n+1) {
  clear: both;
  height: auto;
}
.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  float: left;
}

p.secondary.hollow.button {
  margin-bottom: 1rem;
}
</style>
